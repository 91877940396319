.stockSummaryBanner {
    background-color: silver;
    color: black;
    padding: .1rem;
    width: 80vw;
    margin: auto;
    display: flex;
}

.col {
    border-right: .1rem solid black;
    padding: .5rem;
    font-size: 1rem;
    flex: 1;
}

/* remove margin from p types */
p {
    margin: 0;
    text-align: center;
}