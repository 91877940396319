.nav {
    /* background-color: transparent; */
    background-color: black;
    color: white;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 2rem;
    padding: 1rem;
}

.sticky {
    position: fixed;
    width: 100%;
    top: 0;
}

.nav-padding {
    height: 3.25rem;
}

.hamburger {
    /* # removes default border on button element */
    border: 0;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #6355fa;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    /* positions the icon to the right and center aligns it vertically */
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: #630fff;
  }



.nav-logo {
    font-size: 2rem;
    height: 3.125rem;
    object-fit: cover;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 2rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav li {
    padding-bottom: 0.2rem;
}

.active {
    border-bottom: solid .125rem #6355FA;
}

/* .nav li.active {
    border-bottom: solid .125rem #6355FA;
} */

.nav .nav-link:hover {
    border-bottom: solid .125rem #6355FA;
}

.button-navbar {
    text-decoration: none;
    color: #333333;
    font-size: 0.8rem;
    background:#49F99B;
    border-radius: 6.25rem;
    padding: 0.8rem 1.2rem;
    font-weight: 600;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #6355fa;
    -webkit-transition: ease-out 0.5s;
    -moz-transition: ease-out 0.5s;
    transition: ease-out 0.5s; 
}
  
.button-navbar:hover {
box-shadow: inset 25rem 0 0 0 #6355fa;
color: white;
}

@media screen and (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .nav ul {
        display: none;
    }

    .nav ul.expanded {
        display: block;
        position: absolute;
        top: 4rem;
        left: 0;
        /* stacks the li tags vertically  */
        flex-direction: column;
        width: 100%;
        background-color: #333333;
        color: white;
    }

    a.button-slide {
        display: none;
        width: 30%;
    }

    .nav .nav-link {
        height: 3rem;
    }

    /* .nav li.active {
        background-color: #49F99B;
    } */

    /* a.expanded {
        display: block;
        position: absolute;
        top: 3.75rem;
        left: 0;
        flex-direction: column;
        width: 100%;
    }*/
}