:root {
  --background-grey: #333333;
  --dark-offset-color: #201c1c;
  --light-background-color: #e8e8e8;
  --button-gray: #757575
}

body {
  background-color: var(--background-grey);
}

.home-container {
  /* background-color: var(--background-grey); */
}

.pie-chart-padding-container{
  background-color: var(--dark-offset-color);
  padding: 2rem;
  width: 30vw;
  height: 30vw;
  margin-left: 5vw;
  /* margin-right: auto; */
  margin-right: 2rem;
  display: inline-block;
}

.all-pie-chart-container{
  background-color: var(--light-background-color);
  width: 100%;
  height: 50%;
  display: flex;
  padding-top: .5rem;
}

.pie-chart-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart-padding-container {
  background-color: var(--dark-offset-color);
  padding: 2rem;
  width: 40vw;
  height: 30vh;
  /* pull to right */
  display: inline-block;
  /* margin-left: auto; */
  /* margin-right: 5vw; */
}

.chart-container {
  background-color: var(--light-background-color);
  width: 100%;
  height: 100%;
}

.pie-chart-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}