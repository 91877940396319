
/* ---- watchlist table styling ---- */


.watchlist_table{
    /* overflow-y: auto; */
    /* height: 60vh; */
    display: inline-block;
    margin-left: 1rem;
    border: 2px solid black;
    background-color: black;
    width: 40vw;

}

.watchlist_table_data_section{
    overflow-y: auto;
    height: 60vh;
    max-height: 40rem;
    /* display: inline-block; */
    /* margin: 1rem; */
    /* border: 2px solid black; */
    /* background-color: black; */
}

/* ---- watchlist row styling ---- */
.watchlist_header_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    /* width: 60vw; */
    /* keep stuck at top */
    position: sticky;
    background-color: black;
    color: white;
}

.watchlist_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    width: 40vw;
    background-color: var(--light-background-color);;
}

.watchlist_row_heading{
    flex: 1;
    text-align: center;
}

.watchlist_row_heading > *{
    margin: .1rem;
}

.watchlist_row_info{
    flex: 1;
}

.watchlist_row_info > *{
    margin: .1rem;
}

.watchlist_checkbox_section{
    flex: .25;
    text-align: center;
}
