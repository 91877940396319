.login-page-container{
    overflow: auto;
    height: 100vh;
}

.login-container{
    width: 30vw;
    background-color: var(--light-background-color);
    margin: auto;
    margin-top: 5rem;
    text-align: center;
    padding: 2rem;
}

.login-form{
    padding: 0;
    overflow: auto;
}
.login-label{
    display: block;
    text-align: left;
    font-weight: bold;
    /* font-size: 1.5rem; */
    /* margin-bottom: 1rem; */
}

.login-input{
    display: block;
    width: 90%;
    margin-bottom: .5rem;
}

.login-button{
    display: block;
    background-color: #FFC700;
    margin-left: auto;
    margin-right: 0;
    font-weight: bold;
    /* width: 5vw; */
}