.portfolio_page_container{
    /* background-color: #333333; */
    overflow: auto;
    height: 100vh;
}

.portfolio_header_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    width: 60vw;
    /* keep stuck at top */
    position: sticky;
    background-color: black;
    color: white;
}

.portfolio_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    width: 60vw;
    background-color: var(--light-background-color);;
}

.positions_table_container{
    display: inline-block;
    margin: 1rem;
}

.positions_table_data_section{
    overflow-y: auto;
    height: 51vh;
    /* makke margin on top size of portfolio_header_row */
}


.row_section{
    flex: 1;
    text-align: center;
}

.checkbox_row_section{
    flex: .25;
    text-align: center;
    /* margin: .1rem; */
}

.row_section > *{
    margin: .1rem;
}

.right_side_container{
    display: inline-block;
    vertical-align: top;
    /* margin-left: 5rem; */
}

.add_position_button{
    /* margin-left: auto; */

    /* margin-right: auto; */
    /* text-align: center; */
/* center horizontally */
    display: block;
    margin: auto;
    
}

/* -----------Selected Asset Info----------------- */

.selected_asset_details_container{
    vertical-align: top;
    background-color: var(--light-background-color);;
    width: 30vw;
    /* height: 45vh; */
    margin-left: 2rem;
    margin-top: 1rem;
    height: 45vh;
}

.asset_info_container{
    /* border: 1px solid black; */
    /* width: 20vw; */
    margin: .5rem;
    display: flex;
}

.asset_title{
    margin: 0;
}

.info_container_left_half{
    flex: 1;
}

.info_container_right_half{
    flex: 1;
}

/* -------------------------- */

.edit_button{
    float: right;
    margin: .5rem;
    cursor: pointer;
}

.delete_button{
    float: right;
    margin: .5rem;
    cursor: pointer;
}