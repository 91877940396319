/* ----- Add Transaction Modal ------- */
.add_position_modal{
    width: 40vw;
    border: 1px solid black;
    /* display: inline-block; */
    background-color: var(--light-background-color);
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.add_position_modal > *{
   margin-left: 1rem;
}

.add_position_form > *{
    /* display: block; */
    margin: .5rem;
}