/* .asset-header-top-line{
    display: inline-block; 
}*/

.asset-details-page-container{
    /* background-color: var(--background-grey); */
}

.asset-info-container{
    background-color: var(--light-background-color);
    display: inline-block;
    margin: 2rem;
    vertical-align: top;
    border: 3px solid black;
}

.asset-header-ticker{
    display: inline-block;
    margin: .5rem;
    margin-bottom: 0;
}

.asset-header-price{
    display: inline-block;
}

.info-container{
    border: 1px solid black;
    width: 20vw;
    margin: .5rem;
}

/* ---- Purchase Info Container ----  */
/* .purchase-info{ */
    /* width: 20vw; */
/* } */

.purchase-info > *{
    margin: .1rem;
    /* display: inline-block; */
    /* vertical-align: middle; */
}

.total-value{
    border-bottom: 1px solid black;
}

/* ---- Asset Type Info Container ----  */

.asset-type-info > *{
    margin: .1rem;
    /* display: inline-block; */
    /* vertical-align: middle; */
}

/* ---- Scraped Info Container ----  */

.scraped-info > *{
    margin: .1rem;
    /* display: inline-block; */
    /* vertical-align: middle; */
}

/* ---- Notes Textbox ----  */


.notes-container{
    background-color: var(--light-background-color);
    display: inline-block;
    margin-left: 5rem;
    padding: .5rem;
    border: 3px solid black;
    /* pull all the way to the right */
    /* margin-left: 100%; */
    /* margin-left: 100vw; */
    /* margin-left: 100%; */
    /* margin-left: auto; */
    /* margin-right: 5vw; */

}

.notes-label{
    /* margin-left: 5rem; */
    display: block;
    font-size: 1.5rem;
}

.notes-text-box{
    width: 60vw;
    height: 50vh;
    display: block;
}


/* ---------Container for psoitioning yearly finincial table in page------------------- */
.yearly-financial-table-container{
    margin-left: 1rem;
    margin-bottom: 1rem;
}