.saved_expressions_container{
    background-color: var(--light-background-color);
    width: 20vw;
}

.saved_expression_list{
    padding: 0;
}

.saved_expression{
    /* remove bullet */
    list-style-type: none;
    border: 1px solid black;
}

.arrow-icon{
    width: 1rem;
}