.tag{
    border: 1px solid black;
    background-color: #f2f2f2;
    /* make width small as possible */
    width: fit-content;
    min-width: 7rem;
    padding-left: .2rem;
    padding-right: .2rem;
}

.coloredSquare{
    width: 1rem;
    height: 1rem;
    margin-right: .2rem;
    /* background-color: blue; */
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
}

.delete_button{
    /* pull right */
    float: right;
    border: none;
    background: transparent;
    cursor: pointer;
}