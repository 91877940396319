.card-deck-menu {
    color: white;
    width: 90vw;
    margin: 1rem;
}

.card-deck {
    background-color: var(--dark-offset-color);
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
    margin: auto;
}

.card {
    border: black solid;
    /* background-color: whitesmoke; */
    background-color: var(--light-background-color);
    flex: 1;
    min-width: 14rem ;
    margin: 1rem;
    text-align: center;
    cursor: pointer;
    /* margin: auto; */
}

.card-header {
    display: flex;
    align-items: center;
}

.card-ticker {
    text-align: left;
    flex: 1;
    margin: .25rem;
}

.card-price {
    text-align: right;
    flex: 2;
    margin: .25rem;
}

.card-price-percent-change-up {
    font-size: .8rem;
    color: green;
}

.card-price-percent-change-down {
    font-size: .8rem;
    color: red;
}

.card-value {
    text-align: left;
    margin: .25rem;
}

.card-shares {
    text-align: left;
    margin: .25rem;
    border-bottom: black solid;
}

.card-cost {
    text-align: left;
    margin: .25rem;
}

.card-purchase-price {
    text-align: left;
    margin: .25rem;
}

.card-purchase-date {
    text-align: left;
    margin: .25rem;
}