.watchlist_page_container{
    /* background-color: #333333; */
    overflow: auto;
}



/* ---------------------------- */

.left_menu_body_container{
    display: inline-block;
    /* vw is eqaul to left_menu_container */
    width: 15vw;
    /* vertical-align: top; */
    /* margin-left: 1rem; */
}

.left_side_container{
    display: inline-block;
    /* vertical-align: top; */
    /* margin-left: 1rem; */
}

.right_side_container{
    display: inline-block;
    vertical-align: top;
    margin-left: 5rem;
}

/* ---------------------------------- */
/* ------TODO move to watchlist component------ */
.watchlist_table_controls{
    display: flex;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: .25rem;
    width: 40vw;
    background-color: var(--light-background-color);
    padding: .5rem;
}

.add_asset_button{
    border: none;
    background-color: var(--button-gray);
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    width: 3rem; /* Less than full width */
    height: 2.4rem;
    padding: 10px; /* Some padding */
    border-radius: 5px; /* Rounded corners */
    margin-right: .5rem;
    margin-left: auto; /* pull right */
}

.add_asset_button:disabled {
    background-color: #B0B0B0; /* Gray */
    cursor: not-allowed; /* Change cursor on hover */
}




/* ------------------------------------ */
.bottom_section_container{
    display: inline-block;
    background-color: #222222;
    min-height: 30vh;
    /* border: 1px solid black; */
    /* width: 20vw; */
    /* margin: .5rem;
    display: flex; */
}

.inner_page_navbar{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: 1px solid black; */
    width: 85vw;
    /* center */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    /* keep stuck at top */
    /* position: sticky; */
    /* background-color: black; */
    color: white;
}

.inner_page_navbar_item{
    flex: 1;
    text-align: center;
    cursor: pointer;
}

/* ---------Container for psoitioning yearly finincial table in page------------------- */
.yearly_financial_table_container{
    margin-left: 1rem;
    margin-bottom: 1rem;
}


/* --------------------- */
.dividend_table_container{
    background-color: var(--light-background-color);
    height: 50vh;
    max-height: 30rem;
    overflow-y: auto;
    width: 20vw;
    border: 1px solid black;
    margin-left: 1rem;
    display: inline-block;
}

.dividend_table_row{
    /* border: 1px solid black; */
    /* width: 20vw; */
    /* margin: .5rem;*/
    display: flex;
}

.dividend_year_cell{
    flex: 1;
    border: 1px solid black;
    border-right: none;
    text-align: center;
}

.dividend_amount_cell{
    flex: 1;
    border: 1px solid black;
    border-left: none;
    text-align: center;
}

.dividend_chart_container{
    display: inline-block;
    background-color: var(--light-background-color);
    width: 75vw;
    height: 50vh;
    max-height: 30rem;
    /* pull right */
    margin-left: 3rem;
}

.tooltip {
    background-color: white;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    z-index: 10;
}