.trasaction-page-container{
    /* background-color: #333333; */
}

.transaction-table{
    overflow-y: auto;
    height: 80vh;
    width: 50vw;
    margin-left: 1rem;
    /* max-height: 500px; */
    /* display: inline-block; */
}

.transaction-table-menu {
    position: fixed;
    width: 50vw;
    height: 2rem;
    background-color: black;
    /* top: 0; */
}

.transaction-table-menu-padding {
    height: 2rem;
}

.transaction-row{
    background-color: var(--light-background-color);;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px 0; */
    border: 1px solid black;
}

.transaction-row-date{
    flex: 1;
    text-align: center;
}

.transaction-row-heading{
    flex: 1;
    text-align: center;
}

.transaction-row-heading > *{
    display: block;
    margin: .1rem;
}

.transaction-row-shares{
    flex: 1;
    text-align: center;
}

.transaction-row-purchase-info{
    flex: 2;
}

.transaction-row-purchase-info > *{
    display: block;
    margin: .01rem;
}

.transaction-row-checkbox{
    flex: .5;
    /* text-align: center; */
}

/* .transaction-row-type{
    flex: 1;
}

.transaction-row-ticker{
    flex: 1;
} */