/* ---- Yearly Data Table ----  */
.yearly-data-table{
    background-color: var(--light-background-color);
    width: 50vw;
    min-height: 20vh;
    border: 2px solid black;
}


.yearly-data-table-col{
    display: inline-block;
    margin: 1rem;
}