
.selected_asset_details_container{
    display: inline-block;
    vertical-align: top;
    background-color: var(--light-background-color);;
    width: 30vw;
    /* height: 45vh; */
    min-height: 20rem;
    /* max-height: 40rem; */
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.asset_info_container{
    /* border: 1px solid black; */
    /* width: 20vw; */
    margin: .5rem;
    display: flex;
}

.info_container_left_half{
    flex: 1;
}

.info_container_right_half{
    flex: 1;
}

.tags_label{
    margin: 0;
    margin-bottom: .25rem;
}

.tags_container{
    background-color: white;
    padding: .25rem;
}

.tags_container > *{
    margin: .25rem;
}

.delete_button{
    border: none;
    background-color: var(--button-gray);
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    width: 3rem; /* Less than full width */
    height: 2.4rem;
    padding: 10px; /* Some padding */
    border-radius: 5px; /* Rounded corners */
    margin: .5rem;
    float: right;
}

.delete_button:disabled {
    background-color: #B0B0B0; /* Gray */
    cursor: not-allowed; /* Change cursor on hover */
}
.edit_button{
    border: none;
    background-color: var(--button-gray);
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    width: 3rem; /* Less than full width */
    height: 2.4rem;
    padding: 10px; /* Some padding */
    border-radius: 5px; /* Rounded corners */
    margin: .5rem;
    float: right;
}