/* ----- Add Transaction Modal ------- */
.delete_position_modal{
    width: 40vw;
    border: 1px solid black;
    /* display: inline-block; */
    background-color: var(--light-background-color);
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.delete_position_modal > *{
   margin-left: 1rem;
}

/* .add-transaction-form > *{
    margin: .5rem;
} */