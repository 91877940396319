/* -------Left Side Menu ------------- */

.left_menu_container {
    display: inline-block;
    position: fixed;
    left: 0;
    height: 95vh; /* full height - navbar */
    width: 15vw;
    background-color: var(--dark-offset-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 1rem; */
}

.menu_item {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
    /* cursor: pointer; */
    /* transition: background-color 0.3s ease; */
}

.selected_menu_item {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
    background-color: #555555;
}

.menu_item:hover {
    background-color: #555555;
}

.button_container {
    display: flex;
    justify-content: space-between;
    margin-top: auto; /* Push the button to the bottom */
}

.add_list_button {
    background-color: var(--button-gray);
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    width: 3rem; /* Less than full width */
    height: 2.4rem;
    padding: 10px; /* Some padding */
    /* margin-right: 0; */
    /* margin-left: auto; */
    margin-bottom: 1rem;
    /* margin-top: auto; Push the button to the bottom */
    align-self: flex-end; /* Align at the end of the container */
    border-radius: 5px; /* Rounded corners */
}

.delete_list_button {
    background-color: var(--button-gray);
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    cursor: pointer;
    width: 3rem; /* Less than full width */
    height: 2.4rem;
    padding: 10px; /* Some padding */
    margin-right: 1rem;
    margin-bottom: 1rem;
    /* margin-top: auto; Push the button to the bottom */
    align-self: flex-end; /* Align at the end of the container */
    border-radius: 5px; /* Rounded corners */
}